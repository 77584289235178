import Intercom from "@intercom/messenger-js-sdk";
import { useEffect } from "react";
import { Jsonify } from "type-fest";
import { User } from "~/auth/user/User";
import { unixTimestamp } from "~/time-utils";
import { captureException } from "@sentry/remix";
import logger from "~/logger";


export function InitAnonymousIntercom() {
  useEffect(()=>{
    try {
      Intercom({
        app_id: 'ur160kj1',
      });
    }catch (e){
      captureException(e);
      logger.error(e, "could not init intercom for anonymous user");
    }
  }, [])
  return <></>;
}

export function InitLoggedInUserIntercom(props: {user: Jsonify<User>, userHash: string}) {
  useEffect(()=>{
    try {
      Intercom({
        app_id: "ur160kj1",
        user_id: props.user.id,
        name: `${props.user.firstName} ${props.user.lastName}`,
        email: props.user.email,
        phone: props.user.phoneNumber,
        created_at: unixTimestamp(props.user.createdAt),
        user_hash: props.userHash
      });
    }catch (e){
      captureException(e);
      logger.error(e, "could not init intercom for logged in user");
    }
  }, []);
  return <span></span>;
}
